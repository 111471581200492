@import "../../themes/generated/variables.base.scss";

.create-account-form {
  .policy-info {
    margin: 10px 0;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
    font-size: 14px;
    font-style: normal;
    a {
      color: rgba($base-text-color, alpha($base-text-color) * 0.7);
    }
  }

  .login-link {
    color: $base-accent;
    font-size: 16px;
    text-align: center;
    font-family: 'Jost', sans-serif;
  }

  .button-create-account {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .terms {
    text-align: center;
    margin-top: 20px;
    font-family: 'Jost', sans-serif;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .check-terms {
      margin-right: 0.5rem;
    }
    a {
      margin-left: 0.5rem;
    }
  }
}

.content-modal {
  text-align: center;
  background-image: linear-gradient(to right, #1C4738, #112e24);
  height: 60vh;
  margin: -25px;

  .content-texts-modal-welcome {
    padding: 0 15px;
  }

  header {
    position: absolute;
    width: 100%;
    background: #ff5722;
    height: 10px;
    top: 0;
  }

  .div-button {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
    margin-top: 10px;

    button {
      border: none;
      background-color: transparent;
      height: 50px;
      width: 50px;
      font-size: 40px;
      color: #ff5722;
    }

    button:hover {
      cursor: pointer;
    }
  }

  img {
    margin-top: 2rem;
    margin-bottom: 5rem;
    width: 150px;
    @media(max-width: 996px)  {
      width: 90px;
    }
  }

  .title-1 {
    font-size: 6rem;
    margin-top: 2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #ff5722;
    @media(max-width: 1335px) {
      font-size: 3rem;
    }
    @media(max-width: 996px)  {
      font-size: 2rem;
    }
  }

  p {
    font-size: 2rem;
    font-family: 'Jost', sans-serif;
    color: #FFFFFF;
    margin-top: 0;
    font-weight: 500;
    @media(max-width: 1335px) {
      font-size: 1.5rem;
    }
    @media(max-width: 996px)  {
      font-size: 1.2rem;
    }
  }

  .title-bottom {
    font-size: 1.2rem;
    color: #FFFFFF;
    @media(max-width: 1335px) {
      font-size: 1rem;
    }
    @media(max-width: 996px)  {
      font-size: 0.9rem;
    }
  }

}