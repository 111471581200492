@import "../../themes/generated/variables.base.scss";

.single-card-create-account {

  background-image: url('../../assets/recy3.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  .content {
    height: auto;
  }

  .dx-card {
    width: 600px;
    margin: auto auto;
    padding: 40px;
    flex-grow: 0;

    @media(max-width: 960px) {
      width: 500px;
    }

    .screen-x-small & {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      border: 0;
      flex-grow: 1;
    }

    .header {
      margin-bottom: 30px;

      .title {
        color: $base-text-color;
        line-height: 28px;
        font-weight: 500;
        font-size: 24px;
      }
      .logouzeh {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 180px;
      }

      .description {
        color: rgba($base-text-color, alpha($base-text-color) * 0.7);
        line-height: 18px;
      }
    }
  }
}
