@import "../../themes/generated/variables.base.scss";

.footer {
  display: flex;
  color: #2d6e58;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 20px;
  padding-bottom: 24px;
  font-weight: 400;
  flex-wrap: wrap;
}

.content-footer {
  font-family: 'Jost';
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1rem;
  flex-wrap: wrap;
  a {
    margin-left: 2rem;
    font-size: 1.2rem;
    text-decoration: none;
    color:#2d6e58;
    font-weight: bold;
    padding: 1rem 1rem;
    border-radius: 0.25rem;
  }
  a:hover {
    background-color: #2d6e58;
    color: #FFFFFF;
    transition: 0.3s ease-in;
  }

  .contact-footer{
    margin-left: 2rem;
    font-size: 1.2rem;
    font-weight: bold;
    color:#2d6e58;
    padding: 0.7rem 0.7rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    .logo-whats{
      margin-right: 0.5rem;
      font-size: 2rem;
    }
    div{
      display: flex;
      flex-direction: column;
      span{
        font-size: 0.8rem;
        font-weight: 500;
      }
    }
  }

  .ancor-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.7rem 0.7rem;
    .logo-ancor {
      margin-right: 0.5rem;
    }
  }
}
